import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PlanningRelationModel, {PlanningMapModels
} from "../../../../models/map-data-model-b/new-planning-relation.model";

function Related(props: any) {
    const data: PlanningRelationModel = props.data;

    return (
        <div className="related-planning-container pt-2 pl-2 pr-2 pb-0">
            <h6 className="mb-0">
                {data.planningTypeName}
            </h6>
            <div className="pl-3 pr-2 pt-2 pb-0">
                {data.planningMapModels.map((planningData, index) => (
                    <RenderItemFieldRelatedPlanning
                        handleClick={props.handleClick}
                        key={index}
                        data={planningData}
                    />
                ))}
            </div>
        </div>
    );
}

function RenderItemFieldRelatedPlanning(props: any) {
    const data: PlanningMapModels = props.data;
    const isChecked = useRef(false);
    console.log("RenderItemFieldRelatedPlanning",data);
    
    const handleClick = () => {
        if (data.boundariesView) {
            props.handleClick(data.boundariesView,data.layer, !isChecked.current, data.index);
        } else {
            /*!isChecked.current && ShowNotification(
                "Bạn phải cài đặt ranh giới liên quan",
                NotificationMessageType.Warning
            );*/
            props.handleClick({},data.layer, !isChecked.current, data.index);
        }
        isChecked.current = !isChecked.current;
    };
    if (!(data && data.planningName)) return null;
    return (
        <label className="cursor-pointer">
            <input
                type="checkbox"
                id={`planning-relationship-checkbox${data.planningName}`}
                onClick={() => handleClick()}
            />{" "}
            {data.planningName}
        </label>
    );
}

const mapStateToProps = (state:any) => ({
    openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch:any) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Related);