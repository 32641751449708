export const ApiUrl = {
  //--- Account
  Login: 'api/Account/Login',
  Register: 'api/Account/Register',
  ForgotPassword: 'api/Account/ForgotPassword',
  ResetPassword: 'api/Account/ResetPassword',
  ContactToAdmin: 'api/cms/HomePage/ContactToAdmin',
  GetUserAccountDetail: 'api/Account/GetUserAccountDetail',
  UpdateUserAccount: 'api/Account/UpdateUserAccount',
  ChangeUserPassword: '/api/Account/ChangeUserPassword',
  RefreshToken: '/api/Account/RefreshToken',

  //Home Page
  SlideShow: 'api/cms/HomePage/GetListPostHomePage',

  //Planning
  GetListStatement: 'api/cms/Statement/GetListStatement',
  GetDetailStatement: 'api/cms/Statement/GetDetailStatement',
  SearchPlanning: 'api/admin/Planning/search-planning-by',
  PlanningType: 'api/cms/Planning/get-all-planning-type',
  ApprovalAgencyLevel:
    'api/admin/ApprovalAgencyLevel/get-approval-agency-level',
  ServiceLink: 'api/cms/Statement/GetAllServiceLink',
  SearchStatement: 'api/cms/Statement/search-statement-by',
  PlanningTypeById: 'api/cms/Planning/get-planning-type-by-id',
  PlanningSearchByIdName: 'api/cms/Planning/search-planning-type-by-id-name',
  GetAllPlanningByTypeId: 'api/cms/Planning/get-all-planning',
  GetPlanningById: 'api/cms/Planning/get-planning-by-id',
  GetMapPlanningById: 'api/cms/Planning/get-map-by-planning-id',
  GetPlanningRelationshipByPlanningId:
    'api/cms/Planning/get-planning-relation-by-planning-id',
  GetBoundariesByPlanningId: 'api/cms/Planning/get-boundaries-by-planning-id',
  GetYearStatement: 'api/cms/Planning/get-all-year-statment',
  GetAllBoundariesOfAllPlanning: 'api/cms/Planning/get-all-planning-boundaries',
  GetDetailPlanningById: 'api/cms/Planning/get-planning-by-id',

  //--- Map
  GetMapById: 'api/admin/Map/get-map-by-id',
  MapFilter: 'api/MapTools/MapFilter',
  GetDropDown: 'api/MapTools/GetDropDown',
  SpatialSearch: 'api/MapTools/SpatialSearch',

  //--- Consultant Community
  GetListConsultantCommunity:
    'api/cms/ConsultantCommunity/get-data-list-consultant-community',
  GetDetailConsultantCommunity:
    'api/cms/ConsultantCommunity/get-detail-consult-community',
  GetDetailFormTemplate: 'api/cms/ConsultantCommunity/GetDetailFormTemplate',
  CreateFeedback: 'api/cms/ConsultantCommunity/CreateFeedback',
  UploadFileFeedback: "api/cms/ConsultantCommunity/UploadFileFeedback",
  GetReportFeedback: 'api/cms/ConsultantCommunity/GetReportFeedback',
  GetListExpiredForm: 'api/cms/ConsultantCommunity/GetListForm',
  GetListResultConsultantCommunity:
    'api/cms/ConsultantCommunity/get-data-list-result-consultant-community',

  //--- News
  GetLookupNewsCategories: 'api/admin/News_Category/GetLookup',
  GetListNews: 'api/cms/News/GetListAll',
  GetListNewsByCategory: '/api/cms/News/GetListByCat',
  GetListNewsByTag: '/api​/cms​/News​/GetListByTag​',
  GetListFeatureNews: '/api​/cms​/News​/GetListFeature​',
  GetListHotNews: '/api/cms/News/GetListHot',
  GetDetailNews: '/api/cms/News/GetDetail',
  SearchNews: '/api/cms/News/Search',

  // Document
  DocumentType: 'api/cms/Planning/get-all-document-type',
  SearchDocument: 'api/cms/Planning/search-document-by',
  GetCmsLookupDistrict: 'api/cms/Administrative/GetLookupDistrict',
  GetLookUpPlanningUnit: 'api/admin/PlanningUnit/GetLookup',
  GetDocumentByPlanningId: 'api/cms/Planning/get-document-by-planning-id',
  GetChildrenDocument: 'api/cms/Planning/get-document-by-planning-id-parent-id',
  DownloadFile: 'api/admin/DocumentUpload/DownloadFileBinary',
  GetLookupCommune: 'api/admin/Administrative/GetLookupCommune',

  // Vn2000 convert Wgs84
  Vn2000ToWgs84: '/api/admin/Utils/vn2000-to-wgs84',
  Wgs84ToVn2000: '/api/admin/Utils/wgs84-to-vn2000',

  //planningType
  GetLookupPlanningType: '/api/admin/Planning/GetLookUpPlanningType',

  // Category
  GetLookupCategories: '/api/cms/Categories/GetLookup',
  GetDetailCategories: '/api/admin/Categories/GetById',

  // Reflect
  GetReflectListHomePage: '/api/cms/ReflectTheScene/GetListHomePage',
  GetReflectByCategories: '/api/cms/ReflectTheScene/GetList',
  GetReflectDetail: '/api/cms/ReflectTheScene/GetById',
  GetSimilarReflect: '/api/admin/ReflectTheScene/GetSimilarReflection',
  GetMyListReflect: '/api/cms/ReflectTheScene/GetMyList',
  CreateReflect: '/api/admin/ReflectTheScene/Create',
  UpdateReflect: '/api/admin/ReflectTheScene/Update',
  DeleteReflect: '/api/admin/ReflectTheScene/Delete',

  // Reflect Comment
  GetAllComment: '/api/cms/Comment/GetAll',
  CreateComment: '/api/admin/Comment/Create',
  UpdateComment: '/api/admin/Comment/Update',
  DeleteComment: '/api/admin/Comment/Delete',

  // EvaluateLevelCompletion
  CreateLevel: '/api/admin/EvaluateLevelCompletion/Create',
  UpdateLevel: '/api/admin/EvaluateLevelCompletion/Update',

  // Dashboard
  GetDashboardByReflectionStatus:
    'api/DashBoard/GetDashboardByReflectionStatus',
  GetDashBoardSatisfactionLevelOverall:
    '/api/DashBoard/GetDashBoardSatisfactionLevelOverall',

  //searchPlanningByLatLng
  SearchPlanningByLatLng: '/api/cms/Planning/get-planning-by-coordinate'
};
