const SAVEOPENLAYERCONTROLLER = 'OPENLAYER/SAVEOPENLAYERCONTROLLER';
const SAVEOPENLAYERFEATURESSELECTED = 'OPENLAYER/SAVEFEATURESELECTED';

const CreateSaveOpenlayerControllerAction = data => ({ type: SAVEOPENLAYERCONTROLLER, data: data })
const CreateSaveOpenlayerFeatureSelectedAction = data => ({ type: SAVEOPENLAYERFEATURESSELECTED, data: data })

export const CreateOpenlayerController = (handleFunction) => {
    return dispatch => {
        dispatch(CreateSaveOpenlayerControllerAction(handleFunction))
    }
}

export const SaveOpenlayerFeaturesSelected = (featuresSelected) => {
    return dispatch => {
        dispatch(CreateSaveOpenlayerFeatureSelectedAction(featuresSelected))
    }
}

const InitState = {
    openLayerController: null,
    featuresSelected: [],
}

export default function OpenLayerReducer(state = InitState, action) {
    switch (action.type) {
        case SAVEOPENLAYERCONTROLLER: return { ...state, openLayerController: action.data }
        case SAVEOPENLAYERFEATURESSELECTED: return { ...state, featuresSelected: action.data }
        default: return state
    }
}