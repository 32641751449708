export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    googleMap: "Bản đồ Google",
    satelliteMap: "Bản đồ vệ tinh",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Quy hoạch liên quan",
  planningMapView_result: "Kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Nội dung Xin ý kiến",
  header_lookUpPlanning: "Bản đồ quy hoạch",
  header_news: "Thông tin quy hoạch",
  header_information: "Thông tin",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Hồ sơ quy hoạch",
  header_reflect: "Phản ánh hiện trường",
  header_map: "Bản đồ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_cityPlanning: "Quy hoạch theo Huyện/Thành phố",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  user_info: "Thông tin cá nhân",
  reflect_recommendations: "Phản ánh kiến nghị",
  header_myReflect: "Phản ánh của bạn",
  change_password: "Đổi mật khẩu",
  header_account: "Tài khoản",
  header_title: "GIS Tiên Yên",
  footer_titleName: "CSDL QH Xây dựng và QH Đô thị TP Long Khánh",
    footer_email: "longkhanhradio@gmail.com",
    footer_email_to: "longkhanhradio@gmail.com",
    footer_address: "Cách Mạng Tháng Tám, phường Xuân An, thành phố Long Khánh, tỉnh Đồng Nai",
    footer_hotline: "02513.877.172",
    footer_hotline_tel: "02513.877.172",
    footer_fax: "024 3976 1295",
    footer_website: "https://longkhanh.dongnai.gov.vn/",
    footer_website_to: "https://longkhanh.dongnai.gov.vn/", 
    contact_map_center_lat: 20.827501724727288,
    contact_map_center_lng: 105.34172903977286,
    contact_map_marker_lat: 20.827501724727288,
    contact_map_marker_lng: 105.34172903977286,
    contact_map_url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4254.141934858458!2d107.24493841095878!3d10.92681528918632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174ff52aeb794c5%3A0x662c28c60e56c8fb!2zVUJORCB0aMOgbmggcGjhu5EgTG9uZyBLaMOhbmg!5e1!3m2!1svi!2sus!4v1732847510788!5m2!1svi!2sus',
};
