//@ts-check
import PageUrls from '../common/url-collect';
import * as Page500ErrorTypes from '../common/error-types-500';
import history from '../common/history'

/**
 * @param {{errorType:string,errorMessage:string}} err
 * @param {string} erroCode
*/
export function handleError500(err, erroCode) {
    if (err.errorType === Page500ErrorTypes.UnhandleException) {
        history.push(PageUrls.Page500 + erroCode);
    } else throw err
}