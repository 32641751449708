import React, { useEffect, useState } from 'react';
import { DomainUserSide, getCookies, getUserInfo, setCookies } from 'utils/configuration';
import TopView from "../top/top.view.jsx";
import HeaderView from "../header/header.view.jsx";
import FooterView from "../footer/footer.view.jsx";
import LoadingWithApiQueue from "../loading/loading-with-queue.view";
import ShowNotification from 'components/react-notifications/react-notifications.js';
import * as viVN from "../../languages/vi-VN.json";
import { NotificationMessageType, } from "../../utils/configuration";
import "./layout.scss";
import { Link } from 'react-router-dom';

export default (props) => {
    const { children } = props;
    const [isShowFormLoginQLHS, setIsShowFormLoginQLHS] = useState(false);
    const [isCancelLogin, setIsCancelLogin] = useState(false);
    const [isRedirectHome, setIsRedirectHome] = useState(false);
    const showFormLogin = getCookies("SHOW_FORM_LOGIN");
    const redirectHome = getCookies("IS_ADMIN");
    const isLogin = getUserInfo() ? true : false;
    const isAdmin = getUserInfo() && getUserInfo().userRole === "ADMIN" ? true : false;
    useEffect(() => {
        setIsShowFormLoginQLHS(showFormLogin === 'true' || !isLogin)
        if (!isLogin) {
            setCookies("SHOW_FORM_LOGIN", !isLogin);
        }
    }, [showFormLogin, isLogin])

    useEffect(() => {
        setIsRedirectHome(isLogin === true && !isAdmin)
        if (!isAdmin) {
            setCookies("IS_ADMIN", isAdmin);
        }
    }, [isLogin, redirectHome, isAdmin])

    useEffect(() => {
        if (isCancelLogin) window.location.replace(DomainUserSide);
    }, [isCancelLogin])

    const showNotifi = () => {
        ShowNotification(
            viVN.Warning.NeedToLoginAdminToAccess,
            NotificationMessageType.Warning
        );
    }

    return (
        <div className="layout-container">
            <TopView />
            <HeaderView
                isAdmin={isAdmin}
                isRedirectHome={isRedirectHome}
                match={props.match}
                isShowFormLoginQLHS={isShowFormLoginQLHS}
                returnUrl={props.match.path}
                setIsCancelLogin={setIsCancelLogin}

            />
            {

                isAdmin ? children : (
                    <div className='redirect_home'>
                        <Link to="/"><h5>&#8592; Trở về Trang chủ</h5></Link>
                    </div>
                )
            }
            <FooterView />
            <LoadingWithApiQueue />
        </div>
    )
}