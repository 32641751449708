import Notifications from "./notification-service";

interface ErrorType {
  code: number;
  message: string;
}

function HandleErrorOnGetGeoloaction(error: ErrorType) {
  switch (error.code) {
    case 1:
      return Notifications.error(
        "Bạn đã từ chối cho phép truy cập vị trí của bạn, do đó tính năng không thể hoạt động."
      );
  }
}

export default HandleErrorOnGetGeoloaction