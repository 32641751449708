/* eslint-disable no-useless-constructor */
import React from "react";
import { connect } from "react-redux";

function AppLoading(props) {
    const { loading } = props;

    return (
        <div>
            {
                loading && <div id="loading" className="loading-app">
                    <div className="canvas">
                        <div className="spinner"></div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    loading: state.app.loading,
});

export default connect(mapStateToProps)(AppLoading);
