import HeaderView from 'components/header/header.view';
import React, { useEffect, useState } from 'react';
import { DomainUserSide, getCookies, getUserInfo, setCookies } from 'utils/configuration';

export const LayoutAuthen = (props) => {
  const { children } = props;
  const [isShowFormLogin, setIsShowFormLogin] = useState(false);
  const [isCancelLogin, setIsCancelLogin] = useState(false);
  const showFormLogin = getCookies("SHOW_FORM_LOGIN");
  const isLogin = getUserInfo() ? true : false;

  useEffect(() => {
    setIsShowFormLogin(showFormLogin === 'true' || !isLogin)
    if (!isLogin) {
      setCookies("SHOW_FORM_LOGIN", !isLogin);
    }
  }, [showFormLogin, isLogin])

  useEffect(() => {
    if (isCancelLogin) window.location.replace(DomainUserSide);
  }, [isCancelLogin])

  return (
    <div style={{ background: '#F5F5F5' }}>
      <HeaderView
        match={props.match}
        isShowFormLogin={isShowFormLogin}
        returnUrl={props.match.path}
        setIsCancelLogin={setIsCancelLogin}
      />
      {isLogin && children}
    </div>
  )
}