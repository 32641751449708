import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './please-consult-the-community.scss'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";

import * as consultantCommunityActions from "../../../redux/store/consultant-community/consultant-community.store";
import { APIUrlDefault } from "../../../utils/configuration";
import dateformat from "dateformat";

function PctCommunityHome(props) {
    const { showLoading } = props;

    //da xin y kien
    const [resultModel, setResultModel] = useState([]);
    const [firstResult, setFirstResult] = useState({})
    const [paging, setPaging] = useState();
    const [currentPage, setCurrentPage] = useState();
    const pageSize = 4;
    //dang xin y kien
    const [consulting, setConsulting] = useState([]);

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([

            onGetListExpiredForm({
                pageIndex: 1,
                pageSize: pageSize,
            }),
            onGetListConsultantCommunity({
                pageIndex: 1,
                pageSize: 2,
            })
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };
    const onGetListConsultantCommunity = (data) => {
        return new Promise((resolve, reject) => {
            consultantCommunityActions.GetListConsultantCommunity(data).then(
                (res) => {
                    setConsulting(
                        res &&
                            res.content &&
                            res.content.items &&
                            res.content.items.length > 0
                            ? res.content.items
                            : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    };
    const onGetListExpiredForm = (data) => {
        return new Promise((resolve, reject) => {
            consultantCommunityActions.GetListResultConsultantCommunity(data).then(
                (res) => {
                    setResultModel(
                        res &&
                            res.content &&
                            res.content.items &&
                            res.content.items.length > 0
                            ? res.content.items
                            : []
                    );
                    setPaging(res.content);
                    setCurrentPage(res.content.pageIndex);
                    setFirstResult(res.content.items[0])
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    };

    const getNextPage = () => {
        let nextPage = 1;
        if (currentPage > 0) {
            nextPage = currentPage + 1;
        }
        onGetListExpiredForm({
            pageIndex: nextPage,
            pageSize: pageSize
        })
    }

    const getPreviousPage = () => {
        let prePage = 1;
        if (currentPage > 1) {
            prePage = currentPage - 1;
        }
        onGetListExpiredForm({
            pageIndex: prePage,
            pageSize: pageSize
        })
    }

    return (
        <div>
            <section className="consult">
                <div className="container">
                    <div className="title__consult">
                        <div className="row">
                            <div className="col">
                                <Link to="/y-kien-cong-dong"><h3>Xin ý kiến cộng đồng</h3></Link>
                            </div>
                        </div>
                    </div>
                    {consulting && consulting.length > 0 ? (
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="title__">
                                    <Link to="">QUY HOẠCH ĐANG XIN Ý KIẾN</Link>
                                </div>
                                <div>
                                    {consulting.map((item, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <Link to={"/xin-y-kien/" + item.id} className="col-lg-6">
                                                    <img
                                                        className="consult__img"
                                                        src={APIUrlDefault + item.avatar}
                                                        onError={(e) =>
                                                            (e.target.src = require("../../../assets/image/noImage.png"))
                                                        }
                                                        alt={item.planningName}
                                                    />
                                                </Link>
                                                <div className="col-lg-6">
                                                    <Link to={"/xin-y-kien/" + item.id} className="consult__name">
                                                        {item.planningName}
                                                    </Link>
                                                    <div className="new-date">
                                                        {dateformat(item.startTime, "dd/mm/yyyy")} - {dateformat(item.endTime, "dd/mm/yyyy")}
                                                    </div>
                                                    <Link to={"/xin-y-kien/" + item.id} className="consulting__title">
                                                        {item.title}
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="title__ row">
                                    <Link to="/y-kien-tong-hop" className="col">KẾT QUẢ XIN Ý KIẾN</Link>
                                    <div className="new-nav col">
                                        {
                                            paging && paging.hasPreviousPage && (
                                                <a className="nav-action" onClick={getPreviousPage}>
                                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0)">
                                                            <path
                                                                d="M2.39225 5.35688L6.8892 9.85234C7.08652 10.0492 7.40622 10.0492 7.60404 9.85234C7.80137 9.65551 7.80137 9.33582 7.60404 9.13899L3.46378 5.00023L7.60354 0.861463C7.80087 0.664638 7.80087 0.344942 7.60354 0.147619C7.40622 -0.0492063 7.08603 -0.0492063 6.8887 0.147619L2.39175 4.64304C2.19746 4.83783 2.19746 5.16255 2.39225 5.35688Z"
                                                                fill="#333333" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect width="10" height="10" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </a>
                                            )
                                        }
                                        {paging && paging.hasNextPage && (
                                            <a className="nav-action" onClick={getNextPage}>
                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0)">
                                                        <path
                                                            d="M7.60775 4.64312L3.1108 0.147661C2.91348 -0.0491642 2.59378 -0.0491643 2.39596 0.147661C2.19863 0.344486 2.19863 0.664181 2.39596 0.861006L6.53622 4.99977L2.39646 9.13854C2.19913 9.33536 2.19913 9.65506 2.39646 9.85238C2.59378 10.0492 2.91397 10.0492 3.1113 9.85238L7.60825 5.35696C7.80254 5.16217 7.80254 4.83745 7.60775 4.64312Z"
                                                            fill="#333333" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0">
                                                            <rect width="10" height="10" fill="white" transform="translate(10 10) rotate(-180)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    {resultModel && resultModel.length > 0 ? (
                                        resultModel.slice(1).map((item, index) => (
                                            <div key={index} className="new-item">
                                                <div className="row">
                                                    <div className='col-4'>
                                                        <Link to={"/y-kien-tong-hop?search=" + item.id} >
                                                            <div className="new-image consult_item">
                                                                <img
                                                                    src={APIUrlDefault + item.avatar}
                                                                    onError={(e) =>
                                                                        (e.target.src = require("../../../assets/image/noImage.png"))
                                                                    }
                                                                    alt={item.planningName}
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className="col-8">
                                                        <Link to={"/y-kien-tong-hop?search=" + item.id} className="consult__name">{item.planningName}</Link>
                                                        <div className="new-date">{dateformat(item.publishDate, "dd/mm/yyyy")}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    ) : (<div className="new-item">Chưa có dữ liệu để hiển thị!</div>)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="title__ row">
                                <Link to="/y-kien-tong-hop" className="col">KẾT QUẢ XIN Ý KIẾN</Link>
                                <div className="new-nav col">
                                    {
                                        paging && paging.hasPreviousPage && (
                                            <a className="nav-action" onClick={getPreviousPage}>
                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0)">
                                                        <path
                                                            d="M2.39225 5.35688L6.8892 9.85234C7.08652 10.0492 7.40622 10.0492 7.60404 9.85234C7.80137 9.65551 7.80137 9.33582 7.60404 9.13899L3.46378 5.00023L7.60354 0.861463C7.80087 0.664638 7.80087 0.344942 7.60354 0.147619C7.40622 -0.0492063 7.08603 -0.0492063 6.8887 0.147619L2.39175 4.64304C2.19746 4.83783 2.19746 5.16255 2.39225 5.35688Z"
                                                            fill="#333333" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0">
                                                            <rect width="10" height="10" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        )
                                    }
                                    {paging && paging.hasNextPage && (
                                        <a className="nav-action" onClick={getNextPage}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0)">
                                                    <path
                                                        d="M7.60775 4.64312L3.1108 0.147661C2.91348 -0.0491642 2.59378 -0.0491643 2.39596 0.147661C2.19863 0.344486 2.19863 0.664181 2.39596 0.861006L6.53622 4.99977L2.39646 9.13854C2.19913 9.33536 2.19913 9.65506 2.39646 9.85238C2.59378 10.0492 2.91397 10.0492 3.1113 9.85238L7.60825 5.35696C7.80254 5.16217 7.80254 4.83745 7.60775 4.64312Z"
                                                        fill="#333333" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width="10" height="10" fill="white" transform="translate(10 10) rotate(-180)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mb-4" >
                                    <Link to={"/y-kien-tong-hop?search=" + firstResult?.id}>
                                        <img
                                            className="consult__img"
                                            src={APIUrlDefault + firstResult?.avatar}
                                            onError={(e) =>
                                                (e.target.src = require("../../../assets/image/noImage.png"))
                                            }
                                            alt={firstResult?.planningName}
                                        />
                                        <p className="consult__name">{firstResult?.planningName}</p>
                                    </Link>
                                    <div className="new-date">{dateformat(firstResult?.publishDate, "dd/mm/yyyy")}</div>
                                    <Link to={"/y-kien-tong-hop?search=" + firstResult?.id}>{firstResult?.title}</Link>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        {resultModel && resultModel.length > 0 ? (
                                            resultModel.slice(1).map((item, index) => (
                                                <div key={index} className="new-item ">
                                                    <div className="row consult_item">
                                                        <Link to={"/y-kien-tong-hop?search=" + item.id} className='col-5'>
                                                            <div className="new-image ">
                                                                <img
                                                                    src={APIUrlDefault + item.avatar}
                                                                    onError={(e) =>
                                                                        (e.target.src = require("../../../assets/image/noImage.png"))
                                                                    }
                                                                    alt={item.planningName}
                                                                />
                                                            </div>
                                                        </Link>
                                                        <div className="col-7">
                                                            <Link to={"/y-kien-tong-hop?search=" + item.id} className="consult__name">{item.planningName}</Link>
                                                            <div className="new-date">{dateformat(item.publishDate, "dd/mm/yyyy")}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))
                                        ) : (<div className="new-item">Chưa có dữ liệu để hiển thị!</div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    listPolygon: state.mapData.listPolygon
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(PctCommunityHome));
