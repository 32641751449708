import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

function Note(props) {
    return(
        <details>
            <summary>
                Chú giải
                <img
                    className="position-absolute icon-dropdow right-position"
                    src={require("../../../../assets/icon/arrow-down.png")}
                    alt="Arrow Down"
                />
                <img
                    className="position-absolute icon-dropright right-position"
                    src={require("../../../../assets/icon/arrow-right.png")}
                    alt="Arrow Right"
                />
            </summary>
        </details>
    );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Note);