/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MenuRightView from "../menu-right/menu-right.view";
import * as statementAction from "../../../redux/store/statement/statement.store";
import "./planning-announcement-detail.scss";
import * as apiConfig from '../../../api/api-config';

export default function PlanningAnnouncementDetailDesktopView(props) {
  const [statementModel, setStatementModel] = useState();

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      statementAction
        .GetDetailStatement(props.match.params.id)
        .then((res) => res && res.content && setStatementModel(res.content))
        .catch((err) => { });
    }
  }, []);

  return (
    <div className="container">
      <div className="planning-announcement-detail mt-4 mb-4">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-8">
            {statementModel && (
              <div className="planning-announcement-content border p-3">
                <h5 className="title">{statementModel.title}</h5>
                <p
                  className="content-html"
                  dangerouslySetInnerHTML={{ __html: statementModel.content }}
                ></p>
                {statementModel.statementDocumentFile && (
                  <a href={apiConfig.api + statementModel.statementDocumentFile}
                    target='_blank'
                    style={{ color: 'blue' }}>
                    Xem quyết định phê duyệt tại đây ./.
                  </a>
                )}
              </div>
            )}
          </div>
          <MenuRightView />
        </div>
      </div>
    </div>
  );
}
