import React, { useEffect, useState } from "react";
import * as documentAction from "../../../../redux/store/document/document.store";
import Select from "react-select";
import history from "../../../../common/history";
import UrlCollect from "../../../../common/url-collect";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const SearchByDistrict = (props) => {
    const { setDistrictSelect, onSubmit, name, setName, districtSelect, isLookup} = props;
    const [listDistrict, setListDistrict] = useState([]);

    useEffect(() => {
        GetLookupDistrict();
    }, []);
    
    const GetLookupDistrict = () => {
        documentAction
            .GetLockUpDistrict()
            .then((res) => {
                setListDistrict(
                    res && res.content && res.content.length > 0
                        ? res.content.map((item) => {
                            return { ...item, label: item.name, value: item.id };
                        })
                        : []
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const handleOnChangeDistrict = (data) => {
        if (data) {
            setDistrictSelect(
                data.map((item) => item.value)
            )
        } else {
            setDistrictSelect([]);
        }
        // if (data) {
        //     setDistrictSelect(
        //         data.map((item) => {
        //             return item.value;
        //         })
        //             .join(",")
        //             .trim()
        //     );
        // } else {
        //     setDistrictSelect("");
        // }
    };
    
    return (
        <div className="search-planning">
                <div className="input-group mb-3 mt-3">
                    <input
                        type="text"
                        name="name"
                        defaultValue={name}
                        onChange={(data) => setName(data.target.value)}
                        //onClick={() => setName('')}
                        className="form-control"
                        placeholder="Tìm kiếm Dự án"
                        aria-label="Tìm kiếm Dự án"
                        aria-describedby="searchFile"
                    />
                </div>

                {listDistrict && listDistrict.length > 0 && (
                    isLookup ? (
                    <Select
                        className="basic-single mb-1"
                        classNamePrefix="select"
                        placeholder="Huyện / Tp"
                        name="district"
                        defaultValue={
                            listDistrict.filter((item)=> {
                                if(districtSelect.some((id) => id == item.id))
                                return item
                              })
                        }
                        isMulti
                        onChange={handleOnChangeDistrict}
                        options={listDistrict}
                        noOptionsMessage={() => "không tồn tại"}
                    />
                    ) : (
                        <Select
                        className="basic-single mb-1"
                        classNamePrefix="select"
                        placeholder="Huyện / Tp"
                        name="district"
                        isMulti
                        onChange={handleOnChangeDistrict}
                        options={listDistrict}
                        noOptionsMessage={() => "không tồn tại"}
                    />
                    )
                    
                )}

                <div className="input-group mb-3 mt-3">
                    <button
                        onClick={onSubmit}
                        type="submit"
                        className="text-uppercase btn btn-info w-100"
                    >
                        <FontAwesomeIcon icon={faSearch} className="mr-1" />
                        Tìm kiếm
                    </button>
                </div>
        </div>
    )
}