import React, { useEffect, useState } from 'react';
import Tabletop from 'tabletop';
import { CircularProgress } from '@material-ui/core';

const GoogleSheetEmbed = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const sheetId = "1WnhePxKGmgRUdB4sUgBKJgwcpcs0zQirZxhqUKyyIx8"; // Thay bằng ID của bạn
  const apiKey = "443f22617f59e119fd1b35f6b54cb885f86eacf7"; // Thay bằng API Key của bạn
  const range = "Sheet1"; // Thay bằng tên sheet hoặc phạm vi dữ liệu

  useEffect(() => {
    fetchSheetData();
    setInterval(() => {
      fetchSheetData();
    }, 1000 * 60 * 5)
  }, []);

  const fetchSheetData = async () => {
    const url = `https://script.google.com/macros/s/AKfycbzJM7XLb8BcC29ErsqGUT17PFjxlx1Id14QM7A1vMwOszzakm5yCBEShLhjYr06R0o2Ww/exec`;
    setIsLoading(true)
    try {
      const response = await fetch(url);
      if (response) {
        const json = await response.json();
        console.log('response', json.content)
        setData(json.content);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching Google Sheet data:", error);
    }
  };

  const getStyle = (cell) => {
    let style = {
      color: cell?.fontColor,
      fontSize: `${cell.fontSize}px`,
      backgroundColor: cell?.backgroundColor,
      fontStyle: cell?.fontStyle,
    }

    if (cell.fontStyle == 'bold') style.fontWeight = 'bold';

    return style
  }

  return (
    <div className='mt-3 mb-3'>
      <div className='d-flex justify-content-center'>{isLoading && <CircularProgress size="40px" />}
      </div>
      <div dangerouslySetInnerHTML={{ __html: data }}></div>

      {/* {data && data.length > 0 && (
        <table className='sheet_data'>
          {data && data.length > 1 && data.map((col, index) => (
            <tr key={index} className={`${index % 2 == 1 ? '' : ''}`}>
              {col && col.length > 0 && col.map((cell, index) => (
                <div key={index}>
                  <td dangerouslySetInnerHTML={{ __html: cell }}></td>
                </div>
              ))}
            </tr>
          ))}
        </table>
      )} */}
    </div>
  );
};

export default GoogleSheetEmbed;
