import "./user-profile.scss";
import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import * as accountAction from "../../redux/store/account/account.store";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import ShowNotification from "../../components/react-notifications/react-notifications";
import {
  APIUrlDefault,
  NotificationMessageType,
} from "../../utils/configuration";
import * as viVN from "../../languages/vi-VN.json";
import { LayoutAuthen } from "components/layout-authen";

export const UserProfile = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [tel, setTel] = useState("");
  const [userId, setUserId] = useState();
  const [avatar, setAvatar] = useState(null);
  const [file, setFile] = useState(null);
  const [avatarReturn, setAvatarReturn] = useState(null);
  const [fullName, setFullName] = useState("");
  const inputRef = useRef(null);

  const onCheckTel = (data) => {
    let err = false;
    if (!data) {
      setError("phoneNumber", { type: "required" });
      err = true;
    } else if (data.toString().length < 10) {
      setError("phoneNumber", { type: "pattern" });
      err = true;
    } else clearErrors(["phoneNumber"]);
    setTel(data);
    return err;
  };

  const getUserInfo = () => {
    accountAction.GetUserAccountDetail().then((res) => {
      setUserId(res?.content?.id);
      setValue("fullName", res?.content?.fullName);
      setValue("email", res?.content?.email);
      setValue("phoneNumber", res?.content?.phoneNumber);
      setValue("address", res?.content?.address);
      setAvatarReturn(res?.content?.avatar);
      setTel(res?.content?.phoneNumber);
      setFullName(res?.content?.fullName);
    });
  };
  const onSubmit = (values) => {
    const payload = {
      id: userId,
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      address: values.address,
      file: avatar,
    };
    accountAction
      .UpdateUserAccount(payload)
      .then((res) => {
        if (res && res.content) {
          ShowNotification(
            viVN.Success.ChangeUserInfoSuccess,
            NotificationMessageType.Success
          );
          setFullName(values.fullName);
        }
      })
      .catch((err) => {
        console.log(err);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      });
  };

  const handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setFile(URL.createObjectURL(event.target.files[0]));
      setAvatar(event.target.files[0]);
      setAvatarReturn(null);
    }
  };

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="ml-2">
        <h4 className="user-info-title row">Thông tin cá nhân</h4>
        <div className="row mt-2">
          <div className="avatar col-sm-4">
            <div className="avatar-img">
              <img
                className="avatar-img"
                src={
                  avatarReturn
                    ? APIUrlDefault + avatarReturn
                    : file || require("../../assets/image/user.png")
                }
              />
              <div className="camera-icon" onClick={handleClickUpload} />
              <input
                type="file"
                name="mediaFile"
                ref={inputRef}
                accept=".png, .jpg, .jpeg"
                className="d-none"
                onChange={handleChangeImage}
              ></input>
            </div>
            <p className="text-secondary mt-3">{fullName}</p>
          </div>
          <div className="col-sm-8 ml-auto">
            <div className="tab-info pl-2 p-4">
              <div className="form-group row">
                <label className="col-lg-4 col-form-label text-left">
                  Họ tên<span className="required"></span>
                </label>
                <div className="col-lg-8 text-left">
                  <input
                    ref={register({
                      required: true,
                    })}
                    type="text"
                    name="fullName"
                    className="form-control input-info"
                    placeholder="Họ và tên"
                  />
                  {errors.fullName && errors.fullName.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label text-left">
                  Số điện thoại<span className="required"></span>
                </label>
                <div className="col-lg-8 text-left">
                  <PhoneInput
                    country={"vn"}
                    value={tel}
                    onChange={onCheckTel}
                    onBlur={() => onCheckTel(tel)}
                    placeholder="Số điện thoại"
                    autoFormat={false}
                    enableSearch={true}
                    countryCodeEditable={false}
                  />
                  <input
                    ref={register({
                      required: true,
                      pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                    })}
                    type="hidden"
                    name="phoneNumber"
                    value={tel}
                    onChange={() => onCheckTel(tel)}
                  />
                  {errors.phoneNumber &&
                    errors.phoneNumber.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.mobilePhone &&
                    errors.phoneNumber.type === "pattern" && (
                      <span className="error">Số điện thoại không hợp lệ</span>
                    )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label text-left">
                  Email<span className="required"></span>
                </label>
                <div className="col-lg-8 text-left">
                  <input
                    ref={register({
                      required: true,
                      pattern:
                        /^[A-Za-z][A-Za-z0-9_\.]{5,32}@[A-Za-z0-9]{2,}(\.[A-Za-z0-9]{2,4}){1,2}$/,
                    })}
                    type="text"
                    name="email"
                    className="form-control input-info"
                    placeholder="Email"
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span className="error">Email không đúng định dạng</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label text-left">
                  Địa chỉ<span className="required"></span>
                </label>
                <div className="col-lg-8 text-left">
                  <input
                    ref={register({
                      required: true,
                    })}
                    type="text"
                    name="address"
                    className="form-control input-info"
                    placeholder="Địa chỉ"
                  />
                  {errors.address && errors.address.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="avatar col-sm-4 d-none">
            <img src={require("../../assets/image/user.png")} />
            <p className="text-secondary">Nguyen Van A</p>
          </div>
          <div className="col-sm-7 ml-auto mr-5">
            <button
              type="submit"
              className="btn btn-submit text-uppercase text-light"
            >
              Lưu thay đổi
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};
