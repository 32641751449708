import React from "react";
import { connect } from "react-redux";

function LoadingWithApiQueue(props) {
    return (
        <div>
            {
                (props.loadingQueue.length > 0) && <div id="loading" className="loading-app">
                    <div className="canvas">
                        <div className="spinner"></div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    loadingQueue: state.loadingState.loadingQueue,
});

export default connect(mapStateToProps, null)(LoadingWithApiQueue);
