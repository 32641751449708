import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import "./../auth.scss";
import PhoneInput from 'react-phone-input-2';
import { faEnvelope, faKey, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import * as viVN from "../../../languages/vi-VN.json";

export const Register = (props) => {
  const { register, watch, setError, clearErrors, getValues, errors, tel, setTel, isRegisterSuccess } = props;

  const password = useRef({});
  password.current = watch("password", "");

  const onChangePassword = () => {
    const { password, confirmPassword } = getValues();
    password === confirmPassword
      ? clearErrors(["confirmPassword"])
      : setError("confirmPassword", { type: "validate" });
  };

  const onCheckTel = (data) => {
    let err = false;
    if (!data) {
      setError("mobilePhone", { type: "required" });
      err = true;
    } else if (data.toString().length < 10) {
      setError("mobilePhone", { type: "pattern" });
      err = true;
    } else clearErrors(["mobilePhone"]);
    setTel(data);
    return err;
  };

  useEffect(() => {
    clearErrors();
  }, [])

  return (
    <div className="col-12 form-group mt-4 auth-container">
      <div className="row">
        {!isRegisterSuccess ?
          <>
            <div className="col-12 form-group">
              <FontAwesomeIcon icon={faUserAlt} className="icon-input" />
              <input
                type="text"
                ref={register({
                  required: true,
                })}
                name="fullName"
                className="form-control"
                placeholder="Nhập họ tên"
              />
              {errors.fullName && errors.fullName.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
            </div>
            <div className="col-12 form-group">
              <FontAwesomeIcon icon={faEnvelope} className="icon-input" />
              <input
                type="text"
                ref={register({
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                name="email"
                className="form-control"
                placeholder="Nhập email"
              />
              {errors.email && errors.email.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="error">Email không đúng định dạng</span>
              )}
            </div>
            <div className="col-12 form-group">
              <PhoneInput
                country={"vn"}
                value={tel}
                onChange={onCheckTel}
                onBlur={() => onCheckTel(tel)}
                placeholder="Số điện thoại"
                autoFormat={false}
                enableSearch={true}
                countryCodeEditable={false}
              />
              <input
                ref={register({
                  required: true,
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                })}
                type="text"
                name="mobilePhone"
                value={tel}
                className="d-none"
              />
              {errors.mobilePhone &&
                errors.mobilePhone.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
              {errors.mobilePhone &&
                errors.mobilePhone.type === "pattern" && (
                  <span className="error">Số điện thoại không hợp lệ</span>
                )}
            </div>
            <div className="col-12 form-group">
              <FontAwesomeIcon icon={faKey} className="icon-input" />
              <input
                type="password"
                ref={register({
                  required: true,
                  minLength: 8,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                })}
                onChange={onChangePassword}
                name="password"
                className="form-control"
                placeholder="Nhập mật khẩu"
              />
              {errors.password && errors.password.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
              {errors.password && errors.password.type === "minLength" && (
                <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
              )}
              {errors.password && errors.password.type === "pattern" && (
                <span className="error">
                  {viVN.Errors.ErrorPassword}
                </span>
              )}
            </div>
            <div className="col-12 form-group mb-4">
              <FontAwesomeIcon icon={faKey} className="icon-input" />
              <input
                type="password"
                ref={register({
                  validate: (value) =>
                    value === password.current || "Mật khẩu không trùng khớp",
                })}
                name="confirmPassword"
                className="form-control"
                placeholder="Nhập lại mật khẩu"
              />
              {errors.confirmPassword &&
                errors.confirmPassword.type === "validate" && (
                  <span className="error">Mật khẩu không khớp</span>
                )}
            </div>
            <div className="col-12 text-center mb-4">
              <button type="submit" className="btn btn-submit w-100 text-uppercase text-light">
                Đăng ký
              </button>
            </div>
          </> :
          <div className="col-12 text-center forgot">
            <img src="./check.png" />
            <h3 className="title text-center w-100 mt-2 font-weight-bold text-uppercase">Đăng ký thành công</h3>
            <span className='text-secondary'>Bạn vui lòng truy cập Email để kích hoạt tài khoản</span>
          </div>
        }
      </div>
    </div>
  )
}