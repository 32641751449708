import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouterComponent = ({ component: Component, layout: Layout, ...rest }) => {
    useEffect(() => {
        console.log("Send tracking to GA", window.location.pathname + window.location.search);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [window.location.pathname, window.location.search]);

    return <Route
        {...rest}
        render={props => (
            <Layout {...props}>
                <Component {...props} />
            </Layout>
        )}
    />
}


export default RouterComponent