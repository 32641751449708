import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./../auth.scss";
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import * as viVN from "../../../languages/vi-VN.json";

export const Login = (props) => {
  const { register, errors, setIsTabForgotPassword, setIsTabLogin, clearErrors } = props;

  const openTabForgotPassword = () => {
    setIsTabForgotPassword(true);
    setIsTabLogin(false);
  }

  useEffect(() => {
    clearErrors();
  }, [])

  return (
    <div className="col-12 form-group mt-4 auth-container">
      <div className="row">
        <div className="col-12 form-group">
          <FontAwesomeIcon icon={faUser} className="icon-input" />
          <input
            type="text"
            name="email"
            className="form-control"
            autoComplete="off"
            placeholder="Email"
            ref={register({
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
          />
          {errors.email && errors.email.type === "required" && (
            <span className="error">Trường này là bắt buộc</span>
          )}
          {errors.email && errors.email.type === "pattern" && (
            <span className="error">Email không đúng định dạng</span>
          )}
        </div>
        <div className="col-12 form-group">
          <FontAwesomeIcon icon={faKey} className="icon-input" />
          <input
            type="password"
            name="password"
            className="form-control"
            autoComplete="off"
            placeholder="Mật khẩu"
            ref={register({
              required: true,
              minLength: 8,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
            })}
          />
          {errors.password && errors.password.type === "required" && (
            <span className="error">Trường này là bắt buộc</span>
          )}
          {errors.password && errors.password.type === "minLength" && (
            <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
          )}
          {errors.password && errors.password.type === "pattern" && (
            <span className="error">
              {viVN.Errors.ErrorPassword}
            </span>
          )}
        </div>
        <div className="col-12 form-group row remember">
          <div className="col-6 text-left">
            <input
              type="checkbox"
              {...register('rememberMe')}
              className="form-check-input"
            />
            <label className="form-check-label">Nhớ tài khoản</label>
          </div>

          <div className="col-6 text-right">
            <span className="forgot-password" onClick={openTabForgotPassword}>
              Quên mật khẩu?
            </span>
          </div>
        </div>
        <div className="col-12 text-center mb-4">
          <button type="submit" className="btn btn-submit w-100 text-uppercase text-light">
            Đăng nhập
          </button>
        </div>
      </div>
    </div>
  )
}