import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config";

const service = new Service();

const PLANNING_TYPE = "PLANNING_TYPE";

const createPlanningType = (data) => ({
  type: PLANNING_TYPE,
  data: data,
});

export const GetListStatement = (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = "",
  email = ""
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);
  email && params.append("title", email);
  return service
    .get(ApiUrl.GetListStatement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailStatement = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.GetDetailStatement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PlanningType = () => {
  return service
    .get(ApiUrl.PlanningType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PlanningTypeDispatch = () => {
  return (dispatch) => {
    return service
      .get(ApiUrl.PlanningType)
      .then((res) => {
        dispatch(createPlanningType((res && res.content) || []));
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const ApprovalAgencyLevel = () => {
  return service
    .get(ApiUrl.ApprovalAgencyLevel)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetSerivceLink = () => {
  return service
    .get(ApiUrl.ServiceLink)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const SearchPlanning = (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = "",
  data
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);
  data && data.name && params.append("name", data.name);
  data && data.planningTypeId && params.append("planningTypeId", data.planningTypeId);
  data && data.place && params.append("place", data.place);
  data && data.numberOfDecisions && params.append("numberOfDecisions", data.numberOfDecisions);
  data && data.yearOfApproval && params.append("yearOfApproval", data.yearOfApproval);
  data && data.approvalAgencyLevelId && params.append("approvalAgencyLevel", data.approvalAgencyLevelId);
  data && data.approvalAgency && params.append("approvalAgency", data.approvalAgency);
  data && data.planningAgency && params.append("planningAgency", data.planningAgency);
  data && data.consultingUnit && params.append("consultantUnit", data.consultingUnit);
  return service
    .get(ApiUrl.SearchStatement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetPlanningTypeById = (
  id,
  pageIndex = 1,
  pageSize = 6,
  sortExpression = ""
) => {
  const params = new URLSearchParams();
  params.append("id", id);
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);

  return service
    .get(ApiUrl.PlanningTypeById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetPlanningById = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.GetPlanningById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetAllPlanningByTypeId = (planningTypeId) => {
  const params = new URLSearchParams();
  params.append("planningTypeId", planningTypeId);
  return service
    .get(ApiUrl.GetAllPlanningByTypeId, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetDetailPlanningById = (planningId) => {
  const params = new URLSearchParams();
  params.append("id", planningId);
  return service
    .get(ApiUrl.GetDetailPlanningById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const InitState = {
  planningType: [],
};

export default function StatementReducer(state = InitState, action) {
  switch (action.type) {
    case PLANNING_TYPE:
      return { ...state, planningType: action.data };
    default:
      return state;
  }
}
