//@ts-check
import React from 'react';
import './page-500.scss';

/**
 * @param {{history:any,match:{params:{id:string}}}} props
*/
function ErrorPage500(props) {
    return (
        <div>
            <h2 className="text-center">500</h2>
            <table>
                <tr>
                    <td>Error type</td>
                    <td>{props.match.params.id}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                </tr>
            </table>
        </div>
    )
}

export default ErrorPage500