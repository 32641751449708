import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
export function ConvertColsDataDetailViewToStandardData(
  cols: LayerSettingsModels.LayerSettingsDisplayColModel[]
): any {
  const result: any = {};
  cols.map((col) => {
    result[col.col] = col.alias;
  });
  return result;
}
