import React from 'react';
import './thumbnail-preview.scss';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'

interface PropsInit {
    backgroundUrl: string,
    urlParams: string,
    previewName: string
}

export default function ThumbnailPreview(props: PropsInit) {
    return (
        <div className="col-12 col-xl-4 thumbnail-preview-container">
            <div className="thumbnail-container d-block position-relative">
                <img src={props.backgroundUrl} className="w-100 thumbnail-image" alt={props.backgroundUrl} />
                <div className="thumbnail-detail-front w-100 h-100 position-absolute">
                    <Link to={props.urlParams}>
                        <button className="thumbnail-button-detail">Xem chi tiết</button>
                    </Link>
                </div>
            </div>
            <div className="preview-title p-2">
                <Link to={props.urlParams} data-tip={props.previewName.length > 80 ? props.previewName : ''}>
                    {props.previewName.length > 80 ? props.previewName.substring(0, 80) + '...' : props.previewName}
                </Link>
                <ReactTooltip
                    place="bottom"
                    effect="solid"
                />
            </div>
        </div>
    )
}