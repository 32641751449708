/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import * as apiConfig from '../../../api/api-config';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faAirFreshener,
  faFolder,
  faChevronRight,
  faEye,
  faDownload,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy, faMap } from "@fortawesome/free-regular-svg-icons";
import { saveAs } from "file-saver";

import * as appActions from "../../../core/app.store";
import * as documentAction from "../../../redux/store/document/document.store";

import UrlCollect from "../../../common/url-collect";
import * as config from "../../../utils/configuration";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import $ from "jquery";
import "./records-detail.scss";
import { FILE_EXTENSION_ICON } from "../../../utils/configuration";

function RecordsManagementDetailDesktopView(props) {
  const {
    onBack,
    planningId,
    tabType,
    showLoading,
    isShowPreview,
    setShowPreview,
    setFile,
    setPlanningId,
    pushParams,
    queryParams,
  } = props;

  const [documents, setDocuments] = useState([]);
  const [detailModels, setDetailModel] = useState();
  const [planningRelationShips, setPlanningRelationShips] = useState([]);
  const [nameFolderTitle, setNameFolderTitle] = useState([]);
  const [nextTab, setNextTab] = useState(false);
  const typeName = "Folder";

  useEffect(() => {
    if (!planningId) return;
    GetDocumentByPlanningId();
  }, [planningId]);

  const GetDocumentByPlanningId = (id = planningId) => {
    showLoading(true);
    documentAction
      .GetDocumentByPlanningId(id)
      .then((result) => {
        if (result && result.content) {
          setDetailModel(result.content);
          setDocuments(
            result.content.documents && result.content.documents.length > 0
              ? result.content.documents
              : []
          );
          setPlanningRelationShips(
            result.content.planningRelationShips &&
              result.content.planningRelationShips.length > 0
              ? result.content.planningRelationShips
              : []
          );
          setNextTab(true);
        } else {
          ShowNotification(
            viVN.Errors[result && result.errorType],
            NotificationMessageType.Warning
          );
          setNextTab(false);
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  function GetChildrenDocument(id = planningId, parentId) {
    showLoading(true);
    documentAction
      .GetChildrenDocument(id, parentId)
      .then((res) => {
        setDocuments(res && res.content ? res.content : []);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }

  function handleOnClickFolder(item) {
    let arrFolderTitle = nameFolderTitle;
    arrFolderTitle.push(item);
    setNameFolderTitle(arrFolderTitle);
    GetChildrenDocument(planningId, item.id);
  }

  function onBanckDocument(item, index) {
    let arr = nameFolderTitle.splice(index, 1);
    setNameFolderTitle(arr);
    GetChildrenDocument(planningId, item.id);
  }

  function onClickRootBtn() {
    let arr = nameFolderTitle;
    setNameFolderTitle((arr = []));
    GetDocumentByPlanningId();
  }

  const onShowFilePreview = (file) => {
    if (file) {
      setShowPreview(true);
      setFile(file);
      queryParams.set('fileId', file.id)
      pushParams();
      $("html, body").animate({ scrollTop: 0 }, 500);
    }
  };

  const renderFilePreview = (file) => {
    let isSupport = config.FilePreviewSupport(file.extension);
    if (isSupport)
      return (
        <a
          onClick={() => {
            isSupport
              ? onShowFilePreview(file)
              : ShowNotification(
                viVN.Warning.FilePreviewNotSupport,
                config.NotificationMessageType.Warning,
                config.NotificationPosition.Center
              );
          }}
          className="mr-4 cursor-pointer"
        >
          <FontAwesomeIcon icon={faEye} className="mr-1" /> Xem
        </a>
      );
  };

  const downloadFileBinary = (id, name) => {
    if (!id) return;
    documentAction.DownloadFile(id).then((res) => {
      const blob = new Blob([res], {
        type: "application/*",
      });
      saveAs(blob, name);
    }).catch((err) => {
      ShowNotification(
        viVN.Errors[err && err.errorType],
        config.NotificationMessageType.Error,
        config.NotificationPosition.TopRight
      );
    });
  };

  const renderIcon = (type = "") => {
    if (!type) return null;

    let _type = type.toLowerCase();
    switch (_type) {
      case FILE_EXTENSION_ICON.DOC:
        return (
          <img
            src={require("../../../assets/icon/doc.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.DOCX:
        return (
          <img
            src={require("../../../assets/icon/doc.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.PPT:
        return (
          <img
            src={require("../../../assets/icon/ppt.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.PDF:
        return (
          <img
            src={require("../../../assets/icon/pdf.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.XLSX:
        return (
          <img
            src={require("../../../assets/icon/excel.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.TIF:
        return (
          <img
            src={require("../../../assets/icon/tiff.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.DWG:
        return (
          <img
            src={require("../../../assets/icon/dwg.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.DNG:
        return (
          <img
            src={require("../../../assets/icon/paper.svg")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.JPG:
      case FILE_EXTENSION_ICON.JPEG:
      case FILE_EXTENSION_ICON.PNG:
        return (
          <img
            src={require("../../../assets/icon/image.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.XLS:
        return (
          <img
            src={require("../../../assets/icon/excel.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.PPTX:
        return (
          <img
            src={require("../../../assets/icon/pptx.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      case FILE_EXTENSION_ICON.TXT:
        return (
          <img
            src={require("../../../assets/icon/txt.png")}
            alt="Folder"
            style={{ width: "16px", height: "16px", marginRight: "5px" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="records-management-detail">
        <div className="title">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="mr-2 icon-back float-left mt-1"
            onClick={onBack}
          />
          <h5>{detailModels ? detailModels.name : ""}</h5>
        </div>

        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              id="detail-tab"
              className={tabType === "detail" ? "nav-link active" : "nav-link"}
              data-toggle="tab"
              role="tab"
              aria-controls="detail"
              aria-selected="false"
              href="#detail"
            >
              Thông tin chi tiết
            </a>
          </li>
          <li className="nav-item">
            <a
              id="profile-tab"
              className={`nav-link ${tabType === "profile" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
              href="#profile"
            >
              Thành phần hồ sơ
            </a>
          </li>
          <li className="nav-item">
            <a
              id="involve-tab"
              className={tabType === "involve" ? "nav-link active" : "nav-link"}
              data-toggle="tab"
              role="tab"
              aria-controls="involve"
              aria-selected="false"
              href="#involve"
            >
              Hồ sơ liên quan
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            id="detail"
            role="tabpanel"
            aria-labelledby="detail-tab"
            className={
              tabType === "detail"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
          >
            {detailModels ? (
              <div className="mt-3">
                <div className="item-detail">
                  <label>Tên đồ án QH:&ensp;</label>
                  <span>{detailModels.name}</span>
                </div>
                <div className="item-detail">
                  <label>Mã quy hoạch:&ensp;</label>
                  <span>{detailModels.code}</span>
                </div>
                <div className="item-detail">
                  <label>Địa điểm:&ensp;</label>
                  <span>{detailModels.location}</span>
                </div>
                <div className="item-detail">
                  <label>Số quyết định:&ensp;</label>
                  <span>{detailModels.numberOfDecisions}</span>
                </div>
                <div className="item-detail">
                  <label>Ngày ban hành:&ensp;</label>
                  <span>
                    {moment(new Date(detailModels.publishDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </div>
                <div className="item-detail">
                  <label>Cơ quan phê duyệt:&ensp;</label>
                  <span>{detailModels.agencySubmitted}</span>
                </div>
                <div className="item-detail">
                  <label>Chủ đầu tư:&ensp;</label>
                  <span>{detailModels.agencySubmitted}</span>
                </div>
                <div className="item-detail">
                  <label>Đơn vị tư vấn:&ensp;</label>
                  <span>{detailModels.consultingUnit}</span>
                </div>
                <div className="item-detail">
                  <label>Dân số (người):&ensp;</label>
                  <span>{detailModels.population}</span>
                </div>
                <div className="item-detail">
                  <label>Diện tích (ha):&ensp;</label>
                  <span>{detailModels.acreage}</span>
                </div>
                <div className="item-detail">
                  <label>Đất xây dựng (ha):&ensp;</label>
                  <span>{detailModels.landForConstruction}</span>
                </div>
                <div className="item-detail">
                  <label>Tờ trình:&ensp;</label>
                  <span>{detailModels.report}</span>
                </div>
              </div>
            ) : (
              <span className="mt-3 ml-3">{"Không có dữ liệu"}</span>
            )}
          </div>
          <div
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
            className={
              tabType === "profile"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
          >
            <div className="mt-3">
              <div className="url d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary pathnode"
                  onClick={() => onClickRootBtn()}
                >
                  <FontAwesomeIcon
                    onClick={() => onClickRootBtn()}
                    icon={faAirFreshener}
                    className="mr-2 text-success"
                  />
                  Thư mục gốc
                </button>
                {nameFolderTitle &&
                  nameFolderTitle.length > 0 &&
                  nameFolderTitle.map((item, index) => (
                    <span key={index}>
                      <FontAwesomeIcon icon={faChevronRight} size="sm" />
                      <button
                        type="button"
                        onClick={() => onBanckDocument(item, index)}
                        className="btn btn-secondary pathnode"
                        disabled={
                          index === nameFolderTitle.length - 1 ? true : false
                        }
                      >
                        {item.name}
                      </button>
                    </span>
                  ))}
              </div>
              <table className="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Tên tài liệu</th>
                  </tr>
                </thead>
                <tbody>
                  {documents &&
                    documents.length > 0 &&
                    documents.map((item, index) => (
                      <tr key={index}>
                        {item.typeName === typeName ? (
                          <td onClick={() => handleOnClickFolder(item)}>
                            <FontAwesomeIcon
                              icon={faFolder}
                              color={"#ff0000"}
                              className="mr-2"
                            />{" "}
                            {item.name}
                          </td>
                        ) : (
                          <td className="detail">
                            {renderIcon(
                              item.extension && item.extension.toLowerCase()
                            )}
                            {item.name}
                            <div className="action">
                              {renderFilePreview(item)}
                              <a className="cursor-pointer" onClick={() => downloadFileBinary(item.id, item.name)}>
                                <FontAwesomeIcon icon={faDownload} /> Tải file
                              </a>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div
            id="involve"
            role="tabpanel"
            aria-labelledby="involve-tab"
            className={
              tabType === "involve"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
          >
            <div className="records-table pb-3">
              <div className="row">
                {planningRelationShips && planningRelationShips.length > 0 ? (
                  planningRelationShips.map((item, index) => (
                    <div
                      className={
                        "col-12 col-md-6 mt-3 " +
                        (isShowPreview ? "col-lg-12" : "col-lg-6")
                      }
                      key={index}
                    >
                      <div className="row ml-n2 mr-n2">
                        <div className="col-4 pl-2 pr-2">
                          <img
                            src={config.APIUrlDefault + item.photo}
                            alt="Ảnh Hồ sơ liên quan"
                            className="img-fluid w-100"
                          />
                        </div>
                        <div className="col-8 pl-2 pr-2">
                          <a
                            className="title"
                            onClick={() => {
                              setPlanningId(item.id);
                              if (nextTab) {
                                setNextTab(false);
                                document.getElementById("detail-tab").click();
                              }
                            }}
                          >
                            <b>{item.name}</b>
                          </a>

                          {/* <Link
                            to={UrlCollect.RecordsManager}
                            className="title"
                          >
                            <b>{item.name}</b>
                          </Link> */}
                          <p className="mb-1 mt-1">
                            <b>Địa điểm:</b> {item.location}
                          </p>
                          <div>
                            <FontAwesomeIcon
                              icon={faClock}
                              className="mr-2 icon"
                            />
                            <span>
                              {moment(
                                new Date(detailModels.publishDate)
                              ).format("DD/MM/YYYY")}
                            </span>
                          </div>
                          <div>
                            <Link
                              to={UrlCollect.RecordsManager}
                              className="icon"
                              onClick={() => {
                                setPlanningId(item.id);
                                if (nextTab) {
                                  setNextTab(false);
                                  document.getElementById("detail-tab").click();
                                }
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                className="icon mr-2"
                              />
                              <span>Xem hồ sơ</span>
                            </Link>

                            <Link
                              to={UrlCollect.PlanningMap + "/" + item.id}
                              target="_blank"
                              className="icon ml-3"
                            >
                              <FontAwesomeIcon
                                icon={faMap}
                                className="icon mr-2"
                              />
                              <span>Xem bản đồ</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="ml-3 mb-3">Không có dữ liệu</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowPreview && (
        <div
          className="hidden-preview-file"
          onClick={() => setShowPreview(false)}
        >
          <FontAwesomeIcon icon={faChevronRight} color="#FFFFFF" />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordsManagementDetailDesktopView);
